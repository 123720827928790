import React, { useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { ReskinButtonContainer, RebrandBackGround } from '../../styles/adminStyles'
import { RebrandConsentsButtonContainer } from '../../styles/adminPrivacySettingsStyles'
import ODPrivacySettingsRebrand from './ODPrivacySettingsRebrand'
import {
  publishEvent,
  ConsentManageOptionsButtonClickedOD,
  ManageConsentScreenDisplayedOD,
  ManageConsentControlDisplayedOD,
  ManageConsentBackButtonClickedOD,
  ConsentScreenDisplayedOD,
  ConsentDescriptionLearnMoreClickedOD,
  ConsentDescriptionPrivacyClickedOD
} from '../../utils/analyticsOD'

import {
  ReskinMainBodyOD,
  ReskinContainer,
  ReskinBodyListOD,
  ReskinHeader
} from '../../styles/odStyles'
import { GenericCard, Button, EmptyCard } from '@clientos/ui-toolkit'

const ODComponent = (props) => {
  const {
    handleConsentAcceptAllClick,
    handleConsentDeclineAllClick,
    handleManageOptionsContinueClick,
    dataValueStore = {},
    alpacaData = [],
    craftData = {},
    modalCraftData = {},
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    isAlreadyConsented,
    isAnalyticsOn
  } = props
  const [show, setShow] = useState(false)
  const { ffSmbVeneerFonts } = useFlags()

  if (alpacaData) {
    console.log(Object.values(alpacaData)[0].verbiageValue, craftData)
  }

  console.log(`Onboarding Center Component > alpacaData: ${alpacaData}, craftData: ${craftData}`)
  console.log('Onboarding Center Component > valueStore: ', dataValueStore)

  if (alpacaData && craftData && !dataValueStore) {
    const {
      entry: {
        smart_suite_image,
        smart_suite_heading_text,
        smart_suite_body,
        accept_button_text,
        decline_button_text,
        manage_button_text
      }
    } = craftData
    const smbAdminBodyText1 = smart_suite_body[0].body.body_text
    const smbAdminBodyText2 = smart_suite_body[0].body.secondary_body_text
    const smbAdminBodyText3 = smart_suite_body[0].body.supplimental_text
    const { url: smbAdminGrapchicUrl } = smart_suite_image

    const fireAnalyticsEvent = (e) => {
      const closestAnchor = e.target.closest('a')
      if (!closestAnchor || !e.currentTarget.contains(closestAnchor)) return
      if (closestAnchor.href.indexOf('printer-data-collection-notice') > -1) {
        publishEvent(ConsentDescriptionLearnMoreClickedOD)
      } else if (closestAnchor.href.indexOf('privacy') > -1) {
        publishEvent(ConsentDescriptionPrivacyClickedOD)
      } else {
        // Write any default click event
      }
    }

    const getWelcomeContainer = () => {
      return (
        <ReskinContainer>
          {!show && (
            <div id="content-container">
              <ReskinHeader>{smart_suite_heading_text}</ReskinHeader>
              <GenericCard.Body paddingBodyBottom={true} paddingBodyHorizontal={true}>
                <ReskinMainBodyOD id="hpsmart-content">
                  <div>
                    <ReskinBodyListOD
                      onClick={fireAnalyticsEvent}
                      dangerouslySetInnerHTML={{ __html: smbAdminBodyText1 }}
                    />
                    {country === 'us' ? (
                      <ReskinBodyListOD dangerouslySetInnerHTML={{ __html: smbAdminBodyText2 }} />
                    ) : (
                      <ReskinBodyListOD dangerouslySetInnerHTML={{ __html: smbAdminBodyText3 }} />
                    )}
                  </div>
                </ReskinMainBodyOD>

                <ReskinButtonContainer fontFlag={ffSmbVeneerFonts}>
                  {alpacaData && (
                    <Button
                      onClick={handleConsentAcceptAllClick}
                      disabled={acceptAllButtonLoading}
                      type="secondary"
                      id="consent-button"
                      size="medium"
                    >
                      {accept_button_text}
                    </Button>
                  )}
                  {alpacaData && (
                    <Button
                      onClick={handleConsentDeclineAllClick}
                      disabled={declineAllButtonLoading}
                      type="secondary"
                      id="consent-decline-all-button"
                      size="medium"
                    >
                      {decline_button_text}
                    </Button>
                  )}
                  {alpacaData && (
                    <Button
                      onClick={() => {
                        setShow(true)
                        publishEvent(ConsentManageOptionsButtonClickedOD)
                        publishEvent(ManageConsentScreenDisplayedOD)
                        publishEvent(ManageConsentControlDisplayedOD)
                      }}
                      type="secondary"
                      id="consent-manage-options-button"
                      size="medium"
                    >
                      {manage_button_text}
                    </Button>
                  )}
                </ReskinButtonContainer>
              </GenericCard.Body>
            </div>
          )}
          {show && (
            // <Modal
            //   id="regular-modal"
            //   onClose={() => setShow(false)}
            //   show={show}
            //   footer={

            //   }
            // >
            <RebrandBackGround>
              <div
                id="settings-container"
                data-testid="privacy-settings"
                className="privacy-settings-card"
              >
                <ODPrivacySettingsRebrand {...props} />
                <RebrandConsentsButtonContainer
                  id="consents-button-container"
                  fontFlag={ffSmbVeneerFonts}
                >
                  <Button
                    onClick={handleManageOptionsContinueClick}
                    id="smb-consent-continue-button"
                    loading={continueButtonLoading}
                    size="medium"
                  >
                    {modalCraftData.entry.continue_button_text}
                  </Button>
                  <Button
                    onClick={() => {
                      setShow(false)
                      publishEvent(ManageConsentBackButtonClickedOD)
                      publishEvent(ConsentScreenDisplayedOD)
                    }}
                    type="ghost"
                    id="smb-consent-back-button"
                  >
                    {modalCraftData.entry.back_button_text}
                  </Button>
                </RebrandConsentsButtonContainer>
              </div>
            </RebrandBackGround>
            //</Modal>
          )}
        </ReskinContainer>
      )
    }

    return getWelcomeContainer()
  }

  return <ProgressIndicator appearance="linear" />
}

export default ODComponent
