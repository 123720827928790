import { colors, spacing } from '@clientos/ui-toolkit'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  max-height: 100vh;
  min-height: auto;
  overflow-y: hidden;
  box-sizing: border-box;
  max-width: 90vw;
  padding-top: 2vw;
  padding-bottom: 0;
  margin: 0 5vw;
  font-family: 'HP Simplified', Arial, sans-serif;

  @media screen and (min-width: 48rem) {
    overflow: hidden;
  }
  > * {
    margin: 0;
    padding: 16px 0;
    text-align: center;
    :first-child {
      padding-top: 0;
    }
    @media screen and (min-width: 25.875rem) {
      padding: 0px 0px 2px 0px;
    }
  }
  @media (min-width: 53.75rem) {
    font-size: 0.875rem;
    max-width: 64rem;
  }
  }
  @media all and (-ms-high-contrast: active) and (max-width: 1100px),
    all and (-ms-high-contrast: none) and (max-width: 1100px) {
    padding-top: 5vw;
    margin-top: 0;
  }
`

export const BodyContainer = styled.div`
  overflow: auto;
  padding: 0 5vw;
  text-align: 'center';
  @media all and (-ms-high-contrast: active) and (max-width: 1100px),
    all and (-ms-high-contrast: none) and (max-width: 1100px) {
    overflow-y: auto;
    max-height: 75vh;
  }
`

export const MainBody = styled.div`
  &&&& {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    padding: 0;
    width: 100%;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
    font-size: 1rem;
    line-height: 1.25;
    color: #4e4e4e;
    p,
    > span > p {
      margin: 1rem 0;
      > a {
        color: #027aae;
        :hover {
          color: #006691;
        }
        :active {
          color: #004f74;
        }
      }
    }
  }
`

export const Title = styled.h4`
  && {
    @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
      margin-left: 50%;
      transform: translate(-50%);
    }
    color: #313131;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
    line-height: 1.3;
    font-size: 1.25rem;
    font-weight: bold;
  }
`

export const BodyList = styled.span`
  > ul {
    list-style: disc;
    text-align: left;
    margin-left: 2rem;
  }
`

export const Graphic = styled.img`
  @media screen and (min-width: 0\0) and (min-resolution: 0.001dpcm) {
    margin-left: 50%;
    transform: translate(-50%);
  }
  height: 6.25rem;
  width: auto;
`

//RESKIN
export const ReskinContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: hidden;
  box-sizing: border-box;
  && {
  @media screen and (max-width: 576px) {
      padding: ${spacing.space_sm} ${spacing.space_sm} 0 ${spacing.space_sm};
  }

`

export const ReskinHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: ${spacing.space_sm} 0 ${spacing.space_sm} 0;
  font-size: 32px;
  font-family: Forma DJR UI;
  font-weight: 500;


  && {
  @media screen and (max-width: 576px) {
      font-size: 24px;
      padding: ${spacing.space_sm};
  }
`

export const ReskinMainBodyOD = styled.div`
  text-align: left;
  font-size: ${spacing.space_sm};
  line-height: ${spacing.space_l};
  padding-bottom: 85px;
  color: ${colors.inkSecondary};

  && a {
      color: ${colors.interactiveLinkBase};
      text-decoration: underline;
  }

  && {
  @media screen and (max-width: 576px) {
      font-size: 14px;
      line-height: ${spacing.space_m};
      padding-bottom: ${spacing.space_sm};
  }


`
export const ReskinMainBodyWfh = styled.div`
  text-align: left;
  font-size: ${spacing.space_sm};
  line-height: ${spacing.space_l};
  color: ${colors.inkSecondary};

  && {
  @media screen and (max-width: 576px) {
      font-size: 14px;
      line-height: ${spacing.space_m};
  }

  && strong {
    display: block;
  }
`

export const ReskinBodyListWfh = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space_sm};

  && ul {
    list-style: disc !important;
    text-align: left;
    padding-left: ${spacing.space_l};
  }
`

export const ReskinBodyListOD = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.space_sm};

  && ul {
    list-style: disc !important;
    text-align: left;
    padding-top: ${spacing.space_sm};
    padding-left: ${spacing.space_l};
  }
`
