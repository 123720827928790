export const PRINTER_V1 = 'printer-v1'
export const SMB = 'smb'
export const HPSMART = 'hpsmart'
export const HPX = 'hpx'

// v1 purpose id's for printer consents
export const requiredBasicPrinter = 'bizops.device.basicfunct.-.printer-v1.print.npf'
export const printerAnalytics = 'prodsupport.device.telemetry.-.printer-v1.print'
export const printerAdvertizing = 'advertising.device.marketing.-.printer-v1.print'
export const printerPersonlization = 'personalization.device.personalization.-.printer-v1.print'

// purpose id for PIPL, China only
export const piplDataTransfer = 'bizops.device.telemetry.unmanaged.transfer'

//purpose id's SMB Admin (Business)
export const requiredBasicSmb = 'bizops.customerOrg.basicfunct.smb.printer.print.npf'
export const smbAnalytics = 'prodsupport.customerOrg.telemetry.smb.printer.print'
export const smbAdvertizing = 'advertising.customerOrg.marketing.smb.printer.print'

export const ecpCustomerOrg = 'bizops.customerOrg.mps.ecp.printer.print.npf'
