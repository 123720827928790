import React, { useState } from 'react'
import Modal from '@veneer/core/dist/scripts/modal'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  StyledButton,
  Body,
  FooterButton,
  ButtonContainer,
  ReskinButtonContainer,
  ReskinHeader,
  ReskinContainer,
  ReskinBody
} from '../../styles/adminStyles'
import { ConsentsButtonContainer } from '../../styles/adminPrivacySettingsStyles'
import ODPrivacySettings from './ODPrivacySettings'
import {
  Container,
  BodyContainer,
  MainBody,
  BodyList,
  Graphic,
  ReskinMainBodyWfh,
  ReskinBodyListWfh
} from '../../styles/odStyles'
import { GenericCard, Button } from '@clientos/ui-toolkit'

const WFHComponent = (props) => {
  const {
    handleConsentContinueClick,
    alpacaData = [],
    craftData = {},
    modalCraftData = {},
    country,
    loading,
    acceptAllButtonLoading,
    declineAllButtonLoading,
    continueButtonLoading,
    isAlreadyConsented,
    isAnalyticsOn
  } = props
  const [show, setShow] = useState(false)
  const { ffSmbVeneerFonts } = useFlags()

  console.log(
    `Onboarding WFH Center Component > alpacaData: ${alpacaData}, craftData: ${craftData}`
  )

  if (alpacaData && craftData) {
    const {
      entry: {
        smart_suite_image,
        smart_suite_heading_text,
        smart_suite_body,
        accept_button_text,
        decline_button_text,
        manage_button_text,
        continue_button_text
      }
    } = craftData
    const smbAdminBodyText1 = smart_suite_body[0].body.body_text
    const smbAdminBodyText2 = smart_suite_body[0].body.secondary_body_text
    const smbAdminBodyText3 = smart_suite_body[0].body.supplimental_text

    const isRestOfWorld = country.toUpperCase() !== 'US' ? true : false

    const getWelcomeContainer = () => {
      return (
        <ReskinContainer>
          {!show && (
            <ReskinBody>
              <div>
                <ReskinHeader>{smart_suite_heading_text}</ReskinHeader>
                <ReskinMainBodyWfh id="hpsmart-content">
                  <div>
                    <ReskinBodyListWfh dangerouslySetInnerHTML={{ __html: smbAdminBodyText1 }} />
                    {country === 'us' ? (
                      <ReskinBodyListWfh dangerouslySetInnerHTML={{ __html: smbAdminBodyText2 }} />
                    ) : (
                      <ReskinBodyListWfh dangerouslySetInnerHTML={{ __html: smbAdminBodyText3 }} />
                    )}
                  </div>
                </ReskinMainBodyWfh>
              </div>
              <ReskinButtonContainer>
                {alpacaData && (
                  <Button
                    onClick={handleConsentContinueClick}
                    disabled={continueButtonLoading}
                    size="medium"
                    type="primary"
                    id="consent-button"
                  >
                    {continue_button_text}
                  </Button>
                )}
              </ReskinButtonContainer>
            </ReskinBody>
          )}
        </ReskinContainer>
      )
    }

    return getWelcomeContainer()
  }

  return <ProgressIndicator appearance="linear" />
}

export default WFHComponent
