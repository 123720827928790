/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useODConsentHook } from '../hooks'
import ODComponent from './ODComponent'
import ODComponentRebrand from './ODComponentRebrand'
import { useMultiUiBrandContext } from '@jarvis/react-setup-and-onboarding-addons'
import { ONBOARDING_DIRECTOR } from '../../utils/commons'
import { HPSMART } from '../../constants/purposeID'

const ODContainer = ({
  authProvider,
  navigation,
  sessionContext,
  nextStage,
  language,
  country
}) => {
  const deviceUuid = sessionContext?.device?.uuid
  if (!deviceUuid) {
    console.log('Portal Consents >> Error: Device ID Missing')
    return <h3>Device Id missing. Please provide device id.</h3>
  }
  const { uiBrand } = useMultiUiBrandContext()
  const adminSettingsProps = useODConsentHook(
    authProvider,
    navigation,
    sessionContext,
    nextStage,
    language,
    country,
    ONBOARDING_DIRECTOR,
    deviceUuid
  )

  if (uiBrand === HPSMART) {
    return <ODComponent {...adminSettingsProps} />
  }
  return <ODComponentRebrand {...adminSettingsProps} />
}

export default ODContainer
