import React, { useEffect } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import {
  ToggleLayout,
  ToggleLabelText,
  ToggleLabelHeader,
  RebrandAccordionHeaderContent,
  RebrandAccordionHeaderStart,
  RebrandToggleLabelHeader,
  ManageRebrandToggleLabelText,
  RebrandUserConsentsUpdateTitle,
  RebrandUserConsentsUpdateBody,
  RebrandAccordionCardUserConsentsUpdate
} from '../../styles/adminPrivacySettingsStyles'
import Toggle from '@veneer/core/dist/scripts/toggle'
import List from '@veneer/core/dist/scripts/list'
import ListItem from '@veneer/core/dist/scripts/list_item'
import IconChat from '@veneer/core/dist/scripts/icons/icon_chat'
import { determineLocale } from '../../utils/determineLocale'
import { CHINA } from '../../utils/commons'
import {
  publishEvent,
  ManageConsentPrinterRequiredExpandedOD,
  ManageConsentPrinterDataTransferExpandedOD,
  ManageConsentPrinterAnalyticsExpandedOD,
  ManageConsentPrinterAdvertisingExpandedOD,
  ManageConsentPrinterPersonalizationExpandedOD,
  ManageConsentDescriptionLearnMoreClickedOD,
  ManageConsentAdvertisingLearnMoreClickedOD
} from '../../utils/analyticsOD'

import {
  IconJobs,
  IconMegaphone,
  IconChatText,
  IconBarChart,
  IconShare,
  Toggle as UiToggle,
  Accordion
} from '@clientos/ui-toolkit'
const isOD = true

const ODPrivacySettingsRebrand = (props) => {
  const {
    analytics = false,
    advertising = false,
    dataTransfer = false,
    personalizedSuggestions = false,
    setAnalytics,
    setAdvertising,
    setDataTransfer,
    setPersonalizedSuggestions,
    appAnalyticsContent = '',
    advertisingContent = '',
    dataTransferContent = '',
    personalizedSuggestionsContent = '',
    defaultConsentContent = '',
    modalCraftData
  } = props
  const {
    entry: { smart_suite_body, smart_suite_consent_header_text, smart_suite_heading_text }
  } = modalCraftData
  const { country } = determineLocale()
  const smbAdminPrivacySubHeadingText = smart_suite_body[0]?.body?.body_text
  const { ffSmbVeneerFonts } = useFlags()
  const isPipl = country === CHINA

  useEffect(() => {
    publishEvent(ManageConsentPrinterRequiredExpandedOD)
    isPipl && publishEvent(ManageConsentPrinterDataTransferExpandedOD)
    publishEvent(ManageConsentPrinterAnalyticsExpandedOD)
    publishEvent(ManageConsentPrinterAdvertisingExpandedOD)
    publishEvent(ManageConsentPrinterPersonalizationExpandedOD)
  }, [])

  let requiredBasicPrinterTitle,
    appAnalyticsTitle,
    advertisingTitle,
    personalizedTitle,
    dataTransferTitle

  if (!isPipl) {
    requiredBasicPrinterTitle =
      smart_suite_consent_header_text[0]?.consent_headers.consent_header_text
    appAnalyticsTitle = smart_suite_consent_header_text[1]?.consent_headers.consent_header_text
    advertisingTitle = smart_suite_consent_header_text[2]?.consent_headers.consent_header_text
    personalizedTitle = smart_suite_consent_header_text[3]?.consent_headers.consent_header_text
  } else {
    requiredBasicPrinterTitle =
      smart_suite_consent_header_text[0]?.consent_headers.consent_header_text
    dataTransferTitle = smart_suite_consent_header_text[1]?.consent_headers.consent_header_text
    appAnalyticsTitle = smart_suite_consent_header_text[2]?.consent_headers.consent_header_text
    advertisingTitle = smart_suite_consent_header_text[3]?.consent_headers.consent_header_text
    personalizedTitle = smart_suite_consent_header_text[4]?.consent_headers.consent_header_text
  }

  const PrivacyConsentsRebrand = () => {
    if (isPipl) {
      return (
        <Accordion behavior={'multiExpand'}>
          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconJobs />
                  <RebrandToggleLabelHeader>{requiredBasicPrinterTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div style={{ padding: '0px 0px 16px !important;' }}></div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: defaultConsentContent }}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconShare id="graphic-data-transfer" alt="data-transfer" />
                  <RebrandToggleLabelHeader>{dataTransferTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div>
                  <UiToggle
                    data-testid="setDataTransfer-btn"
                    id="data-transfer-toggle"
                    on={dataTransfer}
                    onChange={(e) => {
                      setDataTransfer(e)
                      setAnalytics(false)
                      setAdvertising(false)
                      setPersonalizedSuggestions(false)
                    }}
                  />
                </div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: dataTransferContent }}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconBarChart id="graphic-analytics" alt="analytics" />
                  <RebrandToggleLabelHeader>{appAnalyticsTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div>
                  <UiToggle
                    data-testid="setAnalytics-btn"
                    id="app-analytics-toggle"
                    on={analytics}
                    onChange={(e) => {
                      setAnalytics(e)
                    }}
                  />
                </div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: appAnalyticsContent }}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconMegaphone id="graphic-advertising" alt="advertising" />
                  <RebrandToggleLabelHeader>{advertisingTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div>
                  <UiToggle
                    data-testid="setAdvertising-btn"
                    id="advertising-toggle"
                    on={advertising}
                    onChange={(e) => {
                      setAdvertising(e)
                    }}
                  />
                </div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: advertisingContent }}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconChatText id="graphic-personalized-suggestions" alt="personalized" />
                  <RebrandToggleLabelHeader>{personalizedTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div>
                  <UiToggle
                    data-testid="setPersonalizedSuggestions-btn"
                    id="personalized-suggestions-toggle"
                    on={personalizedSuggestions}
                    onChange={(e) => {
                      setPersonalizedSuggestions(e)
                    }}
                  />
                </div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: personalizedSuggestionsContent }}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    } else {
      return (
        <Accordion behavior={'multiExpand'}>
          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconJobs />
                  <RebrandToggleLabelHeader>{requiredBasicPrinterTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div></div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: defaultConsentContent }}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconBarChart id="graphic-analytics" alt="analytics" />
                  <RebrandToggleLabelHeader>{appAnalyticsTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div>
                  <UiToggle
                    data-testid="setAnalytics-btn"
                    id="app-analytics-toggle"
                    on={analytics}
                    onChange={(e) => {
                      setAnalytics(e)
                    }}
                  />
                </div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: appAnalyticsContent }}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconMegaphone id="graphic-advertising" alt="advertising" />
                  <RebrandToggleLabelHeader>{advertisingTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div>
                  <UiToggle
                    data-testid="setAdvertising-btn"
                    id="advertising-toggle"
                    on={advertising}
                    onChange={(e) => {
                      setAdvertising(e)
                    }}
                  />
                </div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: advertisingContent }}
              />
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item expanded={true}>
            <Accordion.Header>
              <RebrandAccordionHeaderContent>
                <RebrandAccordionHeaderStart>
                  <IconChatText id="graphic-personalized-suggestions" alt="personalized" />
                  <RebrandToggleLabelHeader>{personalizedTitle}</RebrandToggleLabelHeader>
                </RebrandAccordionHeaderStart>
                <div>
                  <UiToggle
                    data-testid="setPersonalizedSuggestions-btn"
                    id="personalized-suggestions-toggle"
                    on={personalizedSuggestions}
                    onChange={(e) => {
                      setPersonalizedSuggestions(e)
                    }}
                  />
                </div>
              </RebrandAccordionHeaderContent>
            </Accordion.Header>
            <Accordion.Body>
              <ManageRebrandToggleLabelText
                dangerouslySetInnerHTML={{ __html: personalizedSuggestionsContent }}
              />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )
    }
  }

  const PrivacyConsents = () => {
    return (
      <List>
        <ListItem
          leadingArea={<IconJobs />}
          centerArea={<ToggleLabelHeader>{requiredBasicPrinterTitle}</ToggleLabelHeader>}
        />
        <ListItem
          leadingArea={
            <ToggleLabelText dangerouslySetInnerHTML={{ __html: defaultConsentContent }} />
          }
        />
        {isPipl && (
          <ListItem
            leadingArea={<IconShare />}
            centerArea={<ToggleLabelHeader>{dataTransferTitle}</ToggleLabelHeader>}
            trailingArea={
              <Toggle
                id="data-transfer-toggle"
                defaultOn={dataTransfer}
                onChange={(e) => {
                  setDataTransfer(e)
                  setAnalytics(false)
                  setAdvertising(false)
                  setPersonalizedSuggestions(false)
                }}
              />
            }
          />
        )}
        {isPipl && (
          <ListItem
            leadingArea={
              <ToggleLabelText dangerouslySetInnerHTML={{ __html: dataTransferContent }} />
            }
          />
        )}
        <ListItem
          leadingArea={<IconBarChart />}
          centerArea={<ToggleLabelHeader>{appAnalyticsTitle}</ToggleLabelHeader>}
          trailingArea={
            <Toggle
              id="app-analytics-toggle"
              disabled={isPipl && !dataTransfer}
              defaultOn={analytics}
              onChange={(e) => {
                setAnalytics(e)
              }}
            />
          }
        />
        <ListItem
          leadingArea={
            <ToggleLabelText dangerouslySetInnerHTML={{ __html: appAnalyticsContent }} />
          }
        />
        <ListItem
          leadingArea={<IconMegaphone />}
          centerArea={<ToggleLabelHeader>{advertisingTitle}</ToggleLabelHeader>}
          trailingArea={
            <Toggle
              id="advertising-toggle"
              disabled={isPipl && !dataTransfer}
              defaultOn={advertising}
              onChange={(e) => {
                setAdvertising(e)
              }}
            />
          }
        />
        <ListItem
          leadingArea={
            <ToggleLabelText
              onClick={fireAnalyticsEvent}
              dangerouslySetInnerHTML={{ __html: advertisingContent }}
            />
          }
        />
        <ListItem
          leadingArea={<IconChat />}
          centerArea={<ToggleLabelHeader>{personalizedTitle}</ToggleLabelHeader>}
          trailingArea={
            <Toggle
              id="personalized-suggestions-toggle"
              disabled={isPipl && !dataTransfer}
              defaultOn={personalizedSuggestions}
              onChange={(e) => {
                setPersonalizedSuggestions(e)
              }}
            />
          }
        />
        <ListItem
          leadingArea={
            <ToggleLabelText dangerouslySetInnerHTML={{ __html: personalizedSuggestionsContent }} />
          }
        />
      </List>
    )
  }

  const fireAnalyticsEvent = (e) => {
    const closestAnchor = e.target.closest('a')
    if (!closestAnchor || !e.currentTarget.contains(closestAnchor)) return
    if (closestAnchor.href.indexOf('printer-data-collection-notice') > -1) {
      publishEvent(ManageConsentDescriptionLearnMoreClickedOD)
    } else if (closestAnchor.href.indexOf('data-sharing-notice') > -1) {
      publishEvent(ManageConsentAdvertisingLearnMoreClickedOD)
    } else {
      // Write any default click event
    }
  }

  return (
    <div>
      <RebrandUserConsentsUpdateTitle
        id="settings-container-title"
        dangerouslySetInnerHTML={{ __html: smart_suite_heading_text }}
      />

      <RebrandUserConsentsUpdateBody
        id="settings-container-body"
        fontFlag={ffSmbVeneerFonts}
        onClick={fireAnalyticsEvent}
        dangerouslySetInnerHTML={{ __html: smbAdminPrivacySubHeadingText }}
        od={isOD}
      />
      <RebrandAccordionCardUserConsentsUpdate>
        <ToggleLayout
          id="settings-container-toggle-layout"
          od={isOD}
          fontFlag={ffSmbVeneerFonts}
          isDesktop={true}
        >
          <PrivacyConsentsRebrand isDesktop={true} isPipl={country === 'cn'} />
        </ToggleLayout>
      </RebrandAccordionCardUserConsentsUpdate>
    </div>
  )
}

export default ODPrivacySettingsRebrand
