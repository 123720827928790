import styled from 'styled-components'
import Button from '@veneer/core/dist/scripts/button'
import { colors, spacing } from '@clientos/ui-toolkit'

export const BodyContainer = styled.div`
  align-items: left;
  padding-left: 2rem;
  padding-right: 2rem;
  background-color: #f8f8f8;
  min-height: 100%;
`

export const Container = styled.div`
  overflow-y: auto;
`

export const BodyList = styled.span`
  > ul {
    list-style: disc;
    text-align: left;
    margin-left: 2rem;
  }
`

export const Body = styled.div`
  &&&& {
    padding: 0;
    width: 100%;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
    font-size: 1rem;
    line-height: 1.5;
    color: #4e4e4e;

    > span {
      > p {
        margin: 1rem 0;
        > a {
          font-weight: bold;
          text-decoration: underline;
          color: #027aae;
          :hover {
            color: #006691;
          }
          :active {
            color: #004f74;
          }
        }
      }
    }
  }
`

export const MainBody = styled.div`
  &&&& {
    padding: 0;
    width: 100%;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
    font-size: 1rem;
    line-height: 1.5;
    color: #4e4e4e;
    margin-top: 1rem;
  }
`

export const Title = styled.div`
  && {
    color: #313131;
    line-height: 1.3;
    font-size: 2.5rem;
    padding-top: 1rem;
    ${(props) =>
      props.fontFlag
        ? `font-family: 'Forma DJR Micro', Arial, sans-serif`
        : `font-family: 'HP Simplified', Arial, sans-serif`};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  justify-content: end;
  margin: 1rem 0 1rem 0;

  > button {
    margin-right: 1rem;
    font-family: ${(props) =>
      props.fontFlag
        ? `'Forma DJR Micro', Arial, sans-serif`
        : `'HP Simplified', Arial, sans-serif`};
  }

  // less than or equal to 414 pixels
  @media screen and (max-width: 767px) {
    display: grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > button:nth-of-type(2) {
      margin-top: 0.625rem;
      margin-bottom: 0.625rem;
    }
  }
`

export const StyledButton = styled(Button)`
  display: block;
  width: auto;
`

export const FooterButton = styled(Button)`
  width: fit-content;
`
//RESKIN
export const ReskinButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: end;
  justify-content: end;
  margin-top: 1rem;
  width: 100%;
  gap: ${spacing.space_xs};

  @media screen and (max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    && > * {
      width: 100%;
    }
  }
`

export const RebrandBackGround = styled.div`
  .clientos-ui-toolkit-accordion .css-1x1n2cj {
    padding: 12px !important;
  }
  .css-hjbwtt {
    margin: 0px;
    padding: 0px 0px 16px !important;
  }

  .clientos-ui-toolkit-accordion [role='button'] {
    & > :nth-child(1) {
      width: 100%;

      & > :nth-child(1) {
        width: 100%;

        & > :nth-child(1) {
          width: 100%;
        }
      }
    }
  }
`

export const ReskinHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;
  overflow-y: hidden;
  box-sizing: border-box;
  padding: ${spacing.space_m} 0 ${spacing.space_m} 0;
  font-size: 32px;
  font-family: Forma DJR UI;
  font-weight: 500;  

  && {
  @media screen and (max-width: 576px) {
      font-size: 24px;
      padding: ${spacing.space_sm};
      align-items: center;
  }
`

export const ReskinContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  box-sizing: border-box;
  height: 100%;
  && {
  @media screen and (max-width: 576px) {
  }

`
export const ReskinBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  overflow-y: hidden;
  box-sizing: border-box;
`
